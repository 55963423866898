@media only screen and (min-width:320px) and (max-width:568px){
    .banner-content h2 {
        font-size: 90px !important;
    }
    .banner-content h4 {
        font-size: 40px !important;
    }
}

@media only screen and (min-width:480px) and (max-width:640px) and (orientation:landscape){
    .banner-content h2 {
        font-size: 100px;
    }
    .banner-content h4 {
        font-size: 44px;
    }
    ul.socialicon li {
        list-style: none;
        display: inline-block;
        margin: 8px 5px;
    }
    .bg-image-home img.cube {
        position: absolute;
        width: 68px;
        left: 2%;
        bottom: -12%;
    }
    .bg-image-home img.spiral {     
        width: 77px;       
    }
    .bg-image-home.Position-after::before {       
        top: 5%;
        left: 5%;        
        width: 87%;
        height: 87%;      
    }
    .bg-image-home.Position-after::after {        
        top: 3px;
        left: 9px;        
        width: 90%;
        height: 90%;        
    }
}

@media (max-width:767px){
    .quick {
        margin-top: 20px;
    }
    .banner-content h2 {font-size: 120px;}
    section#banner-home {padding: 200px 50px 100px;}
    .banner-content h4 {font-size: 53px;}
    .marketbox {
        margin: 50px 0;
        height: auto;
    }
    .nft .row.rowinc .col {
        flex: 100%;
        margin: 0 0 20px 0;
    }
    .direct {
        margin-left: 5rem;
    }
    .direct.comunuity {
        margin-left: 3rem;
        margin-top: 15px;
    }
        .footer-contact {
            margin: 30px 0 0 0 !important;
        }
        .bg-image-home{margin-top:50px;}
        h2.heading {
            margin-top: 40px;
        }
        .icon-wrap {
            margin-bottom: 20px; padding: 10px;
        }
        .row.rowinc {
            margin: 20px;
        }
        section.pt-5.pb-5.nft {
            padding-bottom: 1px !important;
        }
        
        section.marketsec {
            padding: 4rem 0;}
            
}

/* ipad media query start here */
@media (min-width:768px) and (max-width:991px){
   
    .bg-image-home img.cube {       
        bottom: -71%;
    }

    .banner-content h2 {
        font-size: 110px;}
        .banner-content h4 {
            font-size: 54px;}

    section#banner-home {padding: 200px 100px 100px;}
    section.services {
        padding-bottom: 20px;}
        .marketbox{height:auto; padding: 30px;} 
        .direct {
            margin-left: 2rem;
        }
        .direct.comunuity {
            margin-left: 5rem;}

            ul.socialicon li {
                list-style: none;
                display: inline-block;
                margin: 15px 5px;
            }

            .bg-image-home.Position-after::before {
                position: absolute;
                top: 10px;
                left: 10px;
                content: "";
                width: 85%;
                height: 90%;
                background: #6041D3;
                z-index: 9;
                border-radius: 20px;
                transform: rotate(-15deg);
            }
            .bg-image-home.Position-after::after {
                position: absolute;
                top: 4px;
                left: 0;
                content: "";
                width: 93%;
                height: 93%;
                background: #d930e7;
                z-index: 1;
                border-radius: 20px;
                transform: rotate(-22deg);
            }}
/* ipad media query end here */

/* ipad pro media query start here */
@media (min-width:992px) and (max-width:1200px){
    .marketbox {
        height: auto;
    }
    section#banner-home{padding: 200px 100px 100px;}
    .bg-image-home img.cube {        
        bottom: -59%;
    }
}
/* ipad pro media query end here */
