@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min");


@import url("https://cdn.datatables.net/1.10.24/css/jquery.dataTables.min");
@import url("https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js");


// opn sans
@font-face {
    font-family: "Open Sans";
    src: url("././assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype"),
        url("././assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("././assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype"),
        url("././assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("././assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype"),
        url("././assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("././assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype"),
        url("././assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
}

//   poppins
@font-face {
    font-family: "Poppins";
    src: url("././assets/fonts/Poppins/Poppins-Light.ttf") format("truetype"),
        url("././assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: url("././assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype"),
        url("././assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: url("././assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype"),
        url("././assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: "Poppins";
    src: url("././assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype"),
        url("././assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
}

:root {
    // -----Custom-------
    --nft-font-open-sans: "Open Sans", sans-serif;
    --heading-font: "Poppins", sans-serif;
    --heading-bg: #04031a;
    --nft-primary: #2768f5;
    --nft-primary-rgb: 39 104 245;
    --nft-dropdown-bg: #111230;
    --input-outline-primary: #8083ff;
    // --input-outline-primary: #4748AC;
    
    --input-bg-primary: #363770;
    // --input-bg-primary: #22254C;
    
    --select-option-bg: #22254c;
    --icon-active-color: #515395;
    --nft-form-bg: #20274d;
    --nft-modal-bg: #0c0835;
    --nft-price: #55DF1C;
    --nft-card-color: #0F255D;
}

.font-nft-light {
    font-family: var(--nft-font);
    font-weight: 300;
}
.font-nft-regular {
    font-family: var(--nft-font);
    font-weight: 400;
}
.font-nft-semibold {
    font-family: var(--nft-font);
    font-weight: 600;
}
.font-nft-bold {
    font-family: var(--nft-font);
    font-weight: 700;
}

.font-heading-light {
    font-family: var(--heading-font);
    font-weight: 300;
}
.font-heading-regular {
    font-family: var(--heading-font);
    font-weight: 400;
}
.font-heading-semibold {
    font-family: var(--heading-font);
    font-weight: 600;
}
.font-heading-bold {
    font-family: var(--heading-font);
    font-weight: 700;
}
.font-primary {
    color: var(--nft-primary) !important;
}
.nft-btn {
    border-radius: 0.5rem;
}

a.nav-link,
a.dropdown-item {
    color: #ffffff;
    text-decoration: none;
    border-radius: 0.5rem;
    -webkit-transition: border-radius 2s;

    &:focus,
    &:active,
    &:hover {
        color: var(--bs-white);
        background: var(--nft-primary);
        border-radius: 0.5rem;
    }
}
a {
    color: #ffffff;
    text-decoration: none;
    border-radius: 0.5rem;
    -webkit-transition: border-radius 2s;

    &:focus,
    &:active,
    &:hover {
        color: var(--nft-primary);
    }
}
::-webkit-input-placeholder {
    color: var(--bs-white) !important;
}

:-ms-input-placeholder {
    color: var(--bs-white) !important;
}

::placeholder {
    color: var(--bs-white) !important;
    opacity: 0.5 !important;
    font-family: var(--nft-font) !important;
}

.form-control-two {
    border: 1px solid var(--nft-primary);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-white);
    background-color: transparent;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    

    + .fa-search {
        margin-left: -2.5rem;
        background: var(--heading-bg);
        padding: 0.3rem .4rem 0.3rem 0.3rem;
        z-index: 1;
        cursor: pointer;
    }
    &:focus,
    &:active {
        background-color: transparent;
        border-color: var(--nft-primary);
        outline: 0;
        color: #ffffff;
        box-shadow: 0 0 0 0.25rem var(--nft-primary-rgb) 25%;

        + .fa-search {
            color: var(--nft-primary);
        }
    }  
     
}
.form-control-two.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
} 

.form-control-three {
    border: 1px solid var(--input-outline-primary);
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-white);
    background-color: var(--input-bg-primary);
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus,
    &:active {
        background-color: transparent;
        border-color: var(--input-outline-primary);
        outline: 0;
        color: #ffffff;
        box-shadow: 0 0 0 0.25rem var(--input-outline-primary) 25%;
    }
    
}

.form-control-four {
    border: 1px solid #6966c3;
    display: block;
    // padding: 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-white);
    background-color: #010546;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus,
    &:active {
        background-color: transparent;
        border-color: var(--input-outline-primary);
        outline: 0;
        color: #ffffff;
        box-shadow: 0 0 0 0.25rem var(--input-outline-primary) 25%;
    }
    
}

select:after {
    color: white !important;
}
option:checked {
    background-color: var(--input-bg-primary);
}
option:not(:checked) {
    background-color: var(--select-option-bg);
}
.form-control-three.form-select{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
    background-color: var(--input-bg-primary);
    border: 1px solid var(--input-outline-primary);
}

.nft-button {
    color: var(--bs-white);
    background-color: var(--nft-primary);
    border-radius: 0.5rem;
    font-family: var(--heading-font);
    font-weight: 500;
    line-height: initial;

   
}
.nft-button-2 {
    color: var(--bs-white);
    background-color: #669EE9;
    border-radius: 0.5rem;
    font-family: var(--heading-font);
    font-weight: 500;
    line-height: initial;

    &:hover{
        background: #4489e5;;
    color: white;
    }
}
.nft-outline-button {
    border-radius: 0.5rem;
    color: var(--nft-primary);
    border-color: var(--nft-primary);
}
.read-more {
    color: var(--nft-primary);
    font-family: var(--heading-font);
    font-weight: 600;
    cursor: pointer;
}
.nav{
    font-family: var(--heading-font);
}
.dropdown-menu[data-bs-popper] {
    right: 0;
    left: auto;
}

.dropdown-menu.show:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 0rem;
    top: -0.8rem;
    border-top: solid 6px transparent;
    border-left: solid 15px transparent;
    border-right: solid 12px transparent;
    border-bottom: solid 7px #2768f5;
}
.text-muted {
    color: #adb6bf!important;
}

.custom-radius{
    border-radius: 0 8px 8px 0;
  }

// ----------------Media Query--------------------

@media (max-width: 576px) {
    .form-control-three {
        width: 100%;
    }
    option:checked {
        background-color: var(--input-bg-primary);
    }
    option:not(:checked) {
        background-color: var(--select-option-bg);
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
    .form-control-three {
        width: 100%;
    }
    option:checked {
        background-color: var(--input-bg-primary);
    }
    option:not(:checked) {
        background-color: var(--select-option-bg);
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1197px) {
    .form-control-three {
        width: 100%;
    }
    option:checked {
        background-color: var(--input-bg-primary);
    }
    option:not(:checked) {
        background-color: var(--select-option-bg);
    }
}
