@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url("../src/assets/images/body-bg.png");
  background-color: #cccccc;
  background-position-y: center;
}
// --------------------Header----------------------
header {
  .nav-item {
    margin: 0 0.5rem;
    font-family: var(--heading-font);
  }

  background-color: var(--heading-bg);
  color: var(--bs-white);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .navbar-bg {
    padding: 1.5rem 2rem;
  }
}

.header-container {
  .navbar-toggler:f.container-fluid.header-containerocus {
    box-shadow: 0;
  }

  .dropdown-menu.show {
    background: var(--nft-dropdown-bg);
    border: 1px solid var(--nft-primary);
    padding: 0 0;
    border-radius: 0.5rem;
    margin-top: 1.75rem;
    .dropdown-item {
      color: var(--bs-white);
      border-bottom: 1px solid var(--nft-primary);
      border-radius: 0;
      padding: 0.5rem 1rem;

      &:active,
      &:hover {
        color: var(--bs-white);
        background: var(--nft-primary);
      }
    }
  }
}

// -------------------Footer------------------
footer {
  bottom: 0;
  background: var(--heading-bg);
}

.footer-container {
  padding: 0rem 3rem;
  margin: auto;

  h6 {
    color: var(--nft-primary);
    font-family: var(--heading-font);
  }
  p {
    color: var(--bs-white);
  }
}
a > i {
  color: #ffffff;
  text-decoration: none;
  border-radius: 0.5rem;

  &:focus,
  &:active,
  &:hover {
    color: var(--nft-primary) !important;
  }
}
::-webkit-input-placeholder {
  color: var(--bs-white) !important;
}

// -------------------Create_NFT----------------------
.form-container {
  background: var(--nft-form-bg);
  margin: 8.5rem auto 2rem auto;
  width: 79%;
  border-radius: 1rem;
  padding: 2.5rem;
}
.nft-form {
  max-width: 70%;
}
.nft-upload {
  width: 14rem;
  height: 14rem;
  border: 1.5px dashed var(--input-outline-primary);
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;

  img{
    width:100%;
  }

  .icon {
    position: absolute;
    left: 4.85rem;
    top: 4.85rem;
    color: var(--icon-active-color);
    font-size: 4rem;
    cursor: pointer;
    opacity: 0.6;
  }
}
.nft-upload:hover {
  background: var(--input-bg-primary);

  .icon {
    cursor: pointer;
    opacity: 1;
    color: var(--input-outline-primary);
  }
}

.nft-upload input[type="file"] {
  font-size: 1px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.collection-select-icon {
  position: absolute;
  top: 2.9rem;
  font-size: 0.8rem;
  right: 1rem;
  opacity: 0.9;
}
.category-select-icon {
  position: absolute;
  top: 2.9rem;
  font-size: 0.8rem;
  right: 1rem;
  opacity: 0.9;
}
.nft-sell-checkbox {
  display: flex;
  align-items: flex-end;

  label {
    margin: 0 0 0 0.5rem;
    font-weight: 600;
  }

  .form-check-input[type="checkbox"] {
    background-color: var(--input-bg-primary);
    width: 1.5rem;
    height: 1.5rem;
  }
  .form-check-input[type="checkbox"]:checked {
    background-color: var(--nft-primary);
    border: 1px solid var(--nft-primary);

    + label {
      align-items: center;
      color: var(--bs-white);
      transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }
  }
}

.nft-currency-select {
  border: 1px solid var(--input-outline-primary);
  display: block;
  max-width:2.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-white);
  background-color: var(--input-bg-primary);
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus,
  &:active {
    background-color: transparent;
    border-color: var(--input-outline-primary);
    outline: 0;
    color: #ffffff;
    box-shadow: 0 0 0 0.25rem var(--input-outline-primary) 25%;
  }
}

// -------------------modal--------------------------------
.nft-modal {
  color: var(--bs-white);
  .nft-modal-content {
    background: var(--nft-modal-bg);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    max-width: 27rem;
  }
  .nft-modal-header {
    border-bottom: 1px solid var(--nft-primary);
    padding: 1rem 2rem;
  }
  .nft-modal-header button.btn-close {
    background: transparent
      url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27><path d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/></svg>")
      center/1em auto no-repeat;
  }
  .nft-modal-footer {
    border: 0;
    justify-content: center !important;
  }
  .nft-wallets {
    border-radius: 0.5rem;
    margin: 0 2rem 0.5rem 2rem;
    background-color: var(--input-bg-primary);
  }
  .nft-modal-body {
    padding: 2rem 2rem;
  }
  .wallet-button {
    background-color: var(--input-bg-primary);
    border-radius: 0.5rem;
    color: var(--bs-white);
    padding: 0.7rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--input-outline-primary);
      border-radius: 0.5rem;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(128 131 255 / 25%);
    }
  }
}

// -------------------Create_Collection----------------------
.collection-form {
  max-width: 70%;
}

.logo-upload {
  width: 8rem;
  height: 8rem;
  border: 1.5px dashed var(--input-outline-primary);
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;

  img{
    width:100%;
  }

  .icon {
    position: absolute;
    left: 2.5rem;
    top: 2.5rem;
    color: var(--icon-active-color);
    font-size: 3rem;
    cursor: pointer;
    opacity: 0.6;
  }
}
.logo-upload:hover {
  background: var(--input-bg-primary);

  .icon {
    cursor: pointer;
    opacity: 1;
    color: var(--input-outline-primary);
  }
}

.logo-upload input[type="file"] {
  font-size: 1px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.collection-upload {
  height: 11rem;
  border: 1.5px dashed var(--input-outline-primary);
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;

  img{
    
      width: 100%;
      height: 11rem;
      vertical-align: middle;
    }

  .icon {
    position: absolute;
    left:0;
    right:0;
    top: 3.5rem;
    margin: auto;
    display: table;
    color: var(--icon-active-color);
    font-size: 4rem;
    cursor: pointer;
    opacity: 0.6;
  }
}

.collection-upload:hover {
  background: var(--input-bg-primary);

  .icon {
    cursor: pointer;
    opacity: 1;
    color: var(--input-outline-primary);
  }
}

.collection-upload input[type="file"] {
  font-size: 1px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

// -------------------Marketplace----------------------

.NFT {
  padding: 1rem;
  background: #0f255d;
  border-radius: 0.5rem;
  margin: 0.5rem;
  &:hover {
    background-color: #0f379b;
  }
}

a.nft-card:hover {
  color: white;
}
.nft-card {
  background-color: var(--nft-card-color);
  color: var(--bs-white);
  border-radius: 0.5rem;

  width: 22rem;
  max-width: 90%;
  border-radius: 2rem;
  text-decoration: none;
  box-shadow: 0 16px 32px #cfd8dc;
  transition: all 400ms ease;

  .nft-card-body {
    background: #fff;
    border-radius: 1.25rem;
  }

  .nft-card-image {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    // box-shadow: 0 16px 32px #ECEFF1;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .nft-card-info {
    margin: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
    + .last-price {
      border-bottom: 1px solid var(--nft-primary);
      padding: 0 0 0.625rem 0;
    }
  }
  .nft-card-footer {
    border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
    justify-content: space-between;
    align-items: center;
  }
  .nft-footer-btn {
    width: 48%;
    padding: 1rem;
    text-align: center;
    font-weight: 500;
    transition: all 200ms ease;
  }
  .nft-price {
    color: var(--nft-price);
  }
  .nft-card-btn {
    font-size: .675rem;
    border-radius: 0.5rem;
    &:hover {
      color: var(--bs-white);
    }
  }
}
.nft-filter-btn {
  border-radius: 0.5rem 0.5rem 0 0 !important;
  border: 1px solid var(--nft-primary);

  &:active,
  &:hover,
  &:visited {
    background-color: var(--nft-primary);
  }
}
.nft-filter-btn.collapsed {
  border-radius: 0.5rem !important;
}

.nft-filter {
  color: white;

  .active {
    background: var(--nft-primary);
  }
  .price-controls {
    padding: 1rem 0.5rem;

    .input-group
      > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      margin-left: 0;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
  }
  .min-price {
    margin-right: 14px;
  }
  .price-controls input {
    min-width: 5rem;
    height: 2rem;
    margin: 0rem 0.6rem;
  }

  .nft-filter-item {
    background-color: var(--nft-dropdown-bg);
    border: 1px solid var(--nft-primary);
    border-radius: 0 0 0.5rem 0.5rem;
  }

  &li {
    border: 1px solid var(--nft-primary);
    border-radius: 0.5rem;

    &:active {
      background-color: var(--nft-primary);
    }
  }

  li button {
    color: white;
    list-style: none;
    width: 100%;
    text-align: start;
    a {
      color: var(--bs-white);
    }
  }
}

.nft-search {
  .fa-search {
    background: var(--heading-bg);
    color: white;
  }
}

.marketplace-container {
  margin: 7rem auto 2rem auto;
  padding: 0rem 3rem;
}

// ------------------Profile Settings----------------------
.profileSettings-container {
  margin: 7rem auto 2rem auto;
  padding: 0rem 3.5rem;
  color: var(--bs-white);

  .nav-pills .nav-link {
    border: 1px solid var(--nft-primary);
    border-radius: 0.5rem;
    + .nft-nav-link {
      border: 1px solid var(--nft-primary);
      border-radius: 0.5rem;
      .active {
        background-color: var(--nft-primary) !important;
      }
    }
  }
  .profile-nav-content {
    background: var(--nft-form-bg);
    border-radius: 1rem;
    padding: 2.5rem;
    .profile-form {
      max-width: 70%;
    }
    .banner-upload {
      height: 8rem;
      border: 1.5px dashed var(--input-outline-primary);
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
      margin-top: 2rem;
      cursor: pointer;

      img{
        width:100%;
        height:8rem;
        object-fit: cover;
      }

      .icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 2.5rem;
        margin: auto;
        display: table;
        color: var(--icon-active-color);
        font-size: 3rem;
        cursor: pointer;
        opacity: 0.6;
      }
    }
    .banner-upload:hover {
      background: var(--input-bg-primary);

      .icon {
        cursor: pointer;
        opacity: 1;
        color: var(--input-outline-primary);
      }
    }

    .banner-upload input[type="file"] {
      font-size: 1px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

// ------------------Profile Details----------------
.profile-container {
  margin: 5.8rem auto 2rem auto;
  padding: 0rem 3rem;

  .profile-banner {
    .banner-section {
      padding: 0rem;
      position: relative;

      img {
        min-height: 7.5rem;
        max-height: 15.625rem;
        opacity: 0.8;

        position: relative;
        width: 100%;
      }
      .banner-edit {
        position: absolute;
        right: 1%;
        top: 5%;
      }
    }
    .profile-section {
      padding: 0;
      min-height: 5rem;

      .pull-left {
        .user-image {
          max-width: 130px;
          height: 150px;
          border-radius: 0.5rem;
          margin: auto;
          overflow: hidden;
          transform: translate(0rem, -4.5rem);

          img {
            border-radius: 0.5rem;
            width: 100%;
            object-fit: cover;
            display: flex;
            height: 100%;
            overflow: hidden;
          }
        }
      }
      .pull-right {
        .user-detail {
          position: relative;
        }
      }
      .profile-left-part {
        background-color: var(--heading-bg);
      }

      .pull-right {
      }
      .profile-right-section {
        background-color: var(--heading-bg);
      }
      .profile-right-section-row {
        transform: translate(0rem, -4.5rem);
      }

      .nft-wallet-address {
        background: var(--nft-primary);
        color: white;
        padding: 0.3rem 0.1rem 0.3rem 0.6rem;
        border-radius: 0.5rem;

        .wallet-copy {
          background: var(--nft-primary-rgb);
          padding: 0.5rem;
          border-radius: 0.5rem;
          cursor: pointer;
        }
      }
      .user-details {
        transform: translate(-71px, -80px);
      }      
      
    }
  }
  .profile-tab{
    border-bottom: 1px solid var(--nft-primary);
margin: 1rem 0;
padding: 1rem 0;
  }
}

//------------------Collection Details--------------
.collection-info {
  // background-color: var(--input-bg-primary);
  background-color: #232E5D;
  padding: 1.2rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
  justify-content: space-evenly;
  color: var(--bs-white);
  width: 100%;
  max-width: 24rem;

  .col-md-4{
    padding: 0 1.8rem;
  }

  .col-md-4:nth-child(2){
    border-right: 1px solid darkslateblue;
    border-left: 1px solid darkslateblue;
  }
  .col-md-4:nth-child(3){
    padding: 0 1.1rem;
  }
  
  
}
.collection-activity{
  border-bottom: 1px solid var(--nft-primary);
  padding: 0 0 1rem 0;
  display: flex;
    color: var(--bs-white);
}
.collection-activity-status {
  color: var(--bs-white);
}



// -------------MyCollection-----------------
th {
  white-space: nowrap;
}
.collection-container {
  margin: 7rem auto 2rem auto;
  padding: 0rem 3rem;
  color: var(--bs-white);

  .collection-table {
    color: var(--bs-white);

    tbody {
      background-color: var(--input-bg-primary);
      border: 1px solid var(--input-outline-primary);

      tr td {
        vertical-align: middle !important;
      }

      tr {
        border: 1px solid var(--input-outline-primary);
      }
    }
    .collection-table-row {
      padding: 1rem 0 1rem 1rem;

      a:hover,
      &:focus,
      &:active,
      &:visited {
        color: inherit;
      }
    }
    .collection-action {
      cursor: pointer;
    }
    thead {
      border-bottom: 2px solid var(--input-outline-primary);
    }
  }

  .collection-offer {
    background-color: var(--input-bg-primary);
    border-radius: 0.5rem;
    border: 1px solid var(--input-outline-primary);
    border-left: 0.3rem solid var(--input-outline-primary);
    margin: 0 0 3rem 0;
  }
}

// -------------Nft Details----------

.nftDetails-container {
  margin: 7rem auto 2rem auto;
  padding: 0rem 3rem;

  .nft-image {
    object-fit: fill;
    display: flex;
    // justify-content: center;
    margin: 0 0 2rem 0;

    img {
      border-radius: 1rem;
      max-width: 100%;
      max-height: 36rem;
    }
  }
  .nft-details {
    color: var(--bs-white);
    .nft-details-action {
      font-size: 1.5em;
    }
    img {
      max-width: 3.5rem;
      max-height: 3.5rem;
      border-radius: 0.5rem;
    }
    span {
      color: var(--nft-primary);
    }

    .nft-price {
      border: 1px solid var(--nft-primary);
      padding: 2rem;
      border-radius: 1rem;
      margin: 0 0 3rem 0;
      width: 100%;
      display: grid;
      justify-content: center;

      .nft-detail-action-button {
        font-size: 0.675rem;
        padding: 0.275rem 0.75rem;
        line-height: initial;
        &:hover {
          color: var(--bs-white);
        }
      }
    }
    .nft-details-users {
      margin: 2rem 0;
    }
  }
  .nft-detail {
    color: white;
    background-color: var(--nft-form-bg);
    padding: 2rem;
    border-radius: 0.5rem;

    &:first-child {
      margin: 0 0 1rem 0;
    }

    .nft-detail-data {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 1rem;
      background-color: var(--input-bg-primary);
      margin: 1rem 0;
      border-radius: 0.5rem;
      padding: 1rem;

      span#contact-data {
        font-size: 0.8125em;
        font-weight: 700;
      }
      span#token-standard-data {
        font-size: 0.8125em;
        font-weight: 700;
      }
      span#metadata-data {
        font-size: 0.8125em;
        font-weight: 700;
      }
      span:nth-child(2n) {
        text-align: end;
      }
    }
  }
  
}

.nft-details-table {
  margin: 3rem 0;
  .table>:not(:first-child) {
    border-top: 1px solid var(--input-outline-primary);
}

  table {
    color: var(--bs-white);
    thead {
      border-bottom: 1px solid var(--input-outline-primary) !important;
    }
    thead > tr {
      background-color: var(--nft-form-bg);
      border: 0.1px solid #8083ff;
      border-style: outset;
    }
    tbody {
      background-color: var(--input-bg-primary);
      border: 1px solid var(--input-outline-primary);

      .nft-details-table-row {
        padding: 0rem 0 0rem 1rem;
        vertical-align: middle;
      }
      tr td {
        vertical-align: middle;
      }
      .nft-details-table-action {
        cursor: pointer;
      }
    }
  }
}

// ----------------Home----------------

.homepage-container {
  margin: 5.6rem auto 0 auto;
  padding: 0rem 3rem;
  color: var(--bs-white);

  .right-angle {
    font-size: 1.3rem;
    padding-left: 1rem;
  }
  .home-section-1 {
    background: url("./assets/images/home-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position-y: center;
    background-position-x: center;

    user-select: none;

    margin: 0;
    padding: 0;

    // ---------slider----------

    [type="radio"] {
      display: none;
    }

    #slider {
      height: 30vw;
      position: relative;
      perspective: 1000px;
      transform-style: preserve-3d;
    }

    #slider label {
      margin: auto;
      width: fit-content;
      height: 80%;
      border-radius: 4px;
      position: absolute;
      left: 0;
      right: 0;
      cursor: pointer;
      transition: transform 0.4s ease;
    }

    #s1:checked ~ #slide3,
    #s2:checked ~ #slide2,
    #s3:checked ~ #slide1 {
      // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
      transform: translate3d(-30%, 0, -200px);
    }

    #s1:checked ~ #slide3,
    #s2:checked ~ #slide2,
    #s3:checked ~ #slide1 {
      // box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
      transform: translate3d(-15%, 0, -100px);
    }

    #s1:checked ~ #slide1,
    #s2:checked ~ #slide2,
    #s3:checked ~ #slide3 {
      // box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 11px 7px 0 rgba(0, 0, 0, 0.19);
      transform: translate3d(0, 0, 0);
    }

    #s1:checked ~ #slide2,
    #s2:checked ~ #slide3,
    #s3:checked ~ #slide1 {
      // box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
      transform: translate3d(15%, 0, -100px);
    }

    #s1:checked ~ #slide3,
    #s2:checked ~ #slide1,
    #s3:checked ~ #slide2 {
      // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
      transform: translate3d(30%, 0, -200px);
    }

    #slide1 {
      // background: red;
    }
    #slide2 {
      // background: black;
    }
    #slide3 {
      // background: blue;
    }
  }

  .home-section-2 {
    margin: 5rem 0;

    .nft-slider {
      width: 95%;
      margin: auto;
    }

    .card {
      width: 20rem;
      background: transparent;
      border: 1px solid var(--nft-primary);
      color: var(--bs-white);
      border-radius: 1rem;
      .card-img-top {
        max-height: 12rem;
        object-fit: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      .slick-slider {
        margin: auto;
        width: 90%;
      }
      .card-body {
        background: #281836;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        text-align: center;
        font-family: var(--heading-font);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        p:first-child {
          margin: 0 0 0.5rem 0;
        }
        p {
          margin: 0 0;
        }
      }
    }
  }

  .home-section-3 {
    .whats-whiteroom {
      p {
        color: var(--bs-gray-500);
      }
      .right-angle {
        font-size: 1.3rem;
        padding-left: 1rem;
      }
    }
    .border-line {
      border-bottom: 1px solid;
      width: 60px;
      color: #d1d1d1;
    }
    .whats-nft {
      p {
        color: var(--bs-gray-500);
      }
    }
    .right-img-prop {
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
  }
}

.home-section-4 {
  .why-work {
    p {
      color: var(--bs-gray-400);
    }
  }
}

.home-section-5 {
  .website {
    border: 1px solid var(--nft-primary);
    border-radius: 1rem;
    padding: 2rem;
    background-color: #080c2e;
  }
  h3 {
    margin: 0.4rem 0;
  }
  p {
    max-width: 29rem;
  }
  .widgets-content {
    border-left: 1px solid var(--bs-white);
    height: 10rem;
    align-self: center;
  }
  .widget-outer-circle {
    position: relative;
    border: 11px solid #1860aa42;
    border-radius: 50%;

    .widget-bg {
      width: 20rem;
      position: absolute;
      height: 20rem;
      background: #00c1fe00;
      top: -6rem;
      right: -4rem;
      background-image: radial-gradient(#00c1fead, #cef0ff00, #ffffff05);
      border-radius: 50%;
    }

    .widget-inner-circle {
      background: #1860aa42;
      padding: 1rem 0rem;
      border-radius: 50%;
      border: 3px solid #1860aa47;
    }
  }

  .hr-theme-slash-2 {
    display: flex;

    .hr-line {
      width: 100%;
      position: relative;
      align-self: center;
      margin: auto 4px;
      border-bottom: 1px dashed #fff;
    }
    .hr-icon {
      position: relative;
      top: 0px;
      color: #ffffff;
    }
  }

  .icon1 {
    width: 3rem;
    height: 3rem;
    align-self: center;
    font-size: 1.4rem !important; /* !important is here just to override SO styles*/
    color: #091d38;
    background-color: var(--bs-white);
    border-radius: 50%;
    text-align: center;
    line-height: 3rem !important; /* !important is here just to override SO styles*/
  }
  .icon1::after {
    content: "";
    background-color: var(--bs-white);
    height: 2px;
    width: calc(
      100% / 2 - 14rem
    ); /* 6rem width of left circle + (1 + 1)rem its paddings + (1 + 1)rem positioning + 3rem half-width of central circle + 1rem its padding*/
    position: absolute;
    top: 10%;
    margin-top: -1px;
  }

  .widget-steps-count {
    .row:nth-child(1) {
      margin: 0 0 1rem -6rem;
    }
    .row:nth-child(2) {
      margin: 0 0 1rem -3rem;
    }
    .row:nth-child(3) {
      margin: 0 0 1rem -2rem;
    }
    .row:nth-child(4) {
      margin: 0 0 1rem -3rem;
    }
    .row:nth-child(5) {
      margin: 0 0 1rem -6rem;
    }
  }
}

.home-section-6 {
  margin: 5rem 0;
  .nft-benifits-card {
    max-width: 20rem;
    background-color: #00022a;
    border: 1px solid white;
    color: var(--bs-white);
    border-radius: 1rem;
    height: 100%;

    .card-img-benifit {
      max-height: 12rem;
      object-fit: cover;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .card-body {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      text-align: center;
      font-family: var(--heading-font);
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
  .fashion {
    box-shadow: 0px 0px 20px #2768f55e;
  }
}

.home-section-7 {
  .row {
    background-color: #1b1966;
    margin: 3rem 0 0 0;
    padding: 4rem;

    p {
      margin: 0 0 2rem 0;
      ::first-letter {
        font-size: 2rem;
        max-width: 22rem;
      }
    }
  }
}

// ---------dashboard----------
.dashboard-container {
  margin: 8.5rem auto 2rem auto;
  padding: 0rem 2rem;

  .dashboard {
    color: var(--bs-white);


    .nft-request,
    .nft-proposal {
      // background: var(--input-bg-primary);
      background: #22254C;
    border-radius: 1rem;
    padding: 2rem;
    height: 100%;
    }

    .open,
    .close {
      // background: var(--input-outline-primary);
      background: #4748AC;
      padding: 1rem;
      border-radius: 1rem;
    }
    .sp-analysis, .price-analysis {
      // background: var(--input-bg-primary);
      background: #22254C;
      padding: 2rem;
      border-radius: 1rem;
      height: 100%;
    }
  }

  // -----chartinner--------
  .chartContainer {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 360px;
  }
  .chartInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 29%;
    right: 26%;
    bottom: 26%;
    left: 26%;
    border-radius: 50%;
    padding: 1.25em 0;
    line-height: 120%;
    z-index: 1;
    text-align: center;
    overflow: hidden;
  }
  .chartStatus,
  .chartValue {
    // font-weight: bold;
  }
}

// -------------Media_query-------------------
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
  .header-container {
    max-width: 100% !important;
  }
  header .navbar-bg {
    padding: 2rem 1rem;
  }
  div#navbarContent > form {
    padding: 1rem 0 0 0;
  }
  .footer-container {
    max-width: 100% !important;
  }
  .nft-image {
    justify-content: center;

    img {
      max-height: 15rem !important;
    }
  }

  .nft-hr {
    opacity: 0.8;
    background: var(--nft-primary);
    width: 100%;
    margin: 0.5rem 0;
  }

  .nft-form {
    max-width: 100%;
  }
  .collection-form {
    max-width: 100%;
  }
  .form-container {
    width: 100% !important;
    padding: 2rem;
  }
  .profileSettings-container .profile-nav-content .profile-form {
    max-width: 100%;
  }

  .profile-container .nav {
    max-width: 100%;
  }
  .profile-nav {
    // border-top: 1px solid var(--nft-primary);
    padding: 0 0 0.5rem;
    margin: 0 0 0.5rem;
  }
  .nav {
    width: 100%;
  }

  .widget-steps-count {
    .row {
      margin: auto !important;
    }
    .hr-line {
      display: none;
    }
  }

  .home-section-5 .widgets-content {
    border-left: none;
  }

  .home-section-3 .row .col-md-6:nth-child(2) {
    display: none;
  }
  .website {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .collection-container {
    padding: 0rem 1rem !important;
}
.dashboard-container .chartInner {    
  font-size: inherit !important;
    top: 29% !important;
    left: 26% !important;
    border-radius: 50% !important;
    overflow: hidden !important;
}

.order{
  order: 3;
}


}
// -------end media-----
.nav-pills .nav-link {
  border: 1px solid var(--nft-primary);
  border-radius: 0.5rem;
  + .nft-nav-link {
    border: 1px solid var(--nft-primary);

    border-radius: 0.5rem;
    .active {
      background-color: var(--nft-primary) !important;
    }
  }
}
.profile-tab {
  width: 100% !important;
  // padding: 0rem 0 1rem 0;
  // margin: 3rem 0 1.5rem 0;
  justify-content: end;
}


.profile-tab-header{
  border-bottom: 1px solid var(--nft-primary);
  margin: 1rem 0;
  padding: 1rem 0;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
  .nft-slider-card {
    max-width: 12rem !important;
  }
  .card-img-top {
    max-height: 7rem !important;
  }
  .header-container {
    max-width: 100% !important;
  }
  .footer-container {
    max-width: 100% !important;
  }

  div#navbarContent > form {
    padding: 1rem 0 0 0;
  }

  .nft-hr {
    opacity: 0.8;
    background: var(--nft-primary);
    width: 100%;
    margin: 0.5rem 0;
  }
  .nft-form {
    max-width: 100%;
  }
  .collection-form {
    max-width: 100%;
  }
  .form-container {
    max-width: 90%;
  }
  .profile-container .profile-nav-content .profile-form .nav {
    max-width: 100%;
  }

  .collection-table {
    overflow-x: auto;
    display: block;
    max-width: 100%;
  }
  .nft-details-table {
    overflow-x: auto;
    display: block;
    max-width: 100%;
  }

  .dashboard-container .chartInner {
    font-size: small;
    top: 105%;
    left: 23%;
    border-radius: 0;
    overflow: initial;
}
 
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1197px) {
  .nft-slider-card {
    max-width: 15rem;
  }
  .card-img-top {
    max-height: 9rem;
  }
  .header-container {
    max-width: 100% !important;
  }

  .footer-container {
    max-width: 100% !important;
  }
  .nft-hr {
    opacity: 0.8;
    background: var(--nft-primary);
    width: 100%;
    margin: 0.5rem 0;
  }
  .profile-banner .profile-section .pull-right .user-detail {
    position: relative;
    // margin: -4.5rem 0 0 0rem;
  }
  .collection-table {
    overflow-x: auto;
    display: block;
    width: 100%;
  }
  .nft-details-table {
    overflow-x: auto;
    display: block;
    width: 100%;
  }

}

// Large devices (desktops, less than 1366px)
@media (max-width: 1365.9px) {
  .header-container {
    max-width: 100%;
  }
  header .navbar-bg {
    padding: 1.5rem 1rem;
    max-width: 100%;
  }
  .footer-container {
    max-width: 100%;
    padding: 1.5rem 2rem;
  }

  .marketplace-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .nftDetails-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .profile-container {
    padding: 0rem 2rem;
    max-width: 100%;
    margin: 5.6rem auto 2rem auto;
  }
  .profileSettings-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .collection-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .homepage-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .dasboard-container {
    padding: 0rem 2rem;
    max-width: 100%;
  }
  .collection-form, .nft-form{
    max-width: 100%;
}
  // .profile-left-part, .profile-right-section {
  //   max-height: 6.2rem;
  // }
  .dashboard-container .chartInner {
    font-size: small;
    top: 105%;
    left: 23%;
    border-radius: 0;
    overflow: initial;
}
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) and (orientation: portrait) {
  .profile-left-part,
  .profile-right-section {
    max-height: 7rem;
  }
}

// Greater devices (desktops, more than 1366px)
@media (min-width: 1366.1px) {
  .header-container {
    max-width: 79%;
  }
  .footer-container {
    max-width: 79%;
    padding: 1.5rem 0rem;
  }

  header .navbar-bg {
    padding: 2rem 0rem;
  }
  .marketplace-container {
    max-width: 80%;
    padding: 1.5rem 1rem;
  }
  .nftDetails-container {
    max-width: 80%;
    padding: 1.5rem 1rem;
  }
  .profile-container {
    max-width: 80%;
    padding: 0rem 1rem;
    margin: 6.7rem auto 2rem auto;
  }
  .profileSettings-container {
    max-width: 80%;
    padding: 2rem 2rem;
  }
  .collection-container {
    max-width: 80%;
    padding: 1rem 1.5rem;
  }
  .homepage-container {
    max-width: 80%;
    padding: 0rem 1.5rem;
  }
  .dashboard-container {
    max-width: 80%;
    padding: 0rem 1.5rem;
  }

  .nft-hr {
    opacity: 0.8;
    background: var(--nft-primary);
    width: 100%;
    margin: 0.5rem 0;
  }
  .profile-left-part,
  .profile-right-section {
    max-height: 6.2rem;
  }
}

.EditCollection-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section.main-dashboard-custom {
  padding-top: 100px;
  background-color: #0d0c3e;
  padding-bottom: 50px;
}
.container-custom{
  width: 100%;
  max-width: 90%;
  margin: auto;}

  .main-heading h1{
    font-size: 1.5rem;
    color:#fff;
  }
  span.heading-text {
    background-color: #0d0c3e;
    color: #6e66fa;
    border: 1px solid #2762b6;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: .9rem;
}
.card-custom {
  background-color: #03032c;
  padding: 20px 20px 20px 20px;
  min-height: 200px;
}
.card-custom h5 {
  color: #7068fa;
  font-size: .9rem;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}
.price-value h1 {
  color: #fff;
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 0px;
}
.price-value p {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}
span.heading-total {
  color: #fff;
  font-weight: 500;
}
span.heading-total {
  color: #fff;
  font-weight: 500;
}
h3.value-span {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 3px;
}
.total-sold p.total-red {
  color: #c70e0e;
  font-weight: 500;
}
.total-sold {
  background-color: #1d1962;
  padding: 11px 15px 40px 15px;
  min-height: 190px;;
}
.text-inputs {
  padding: 5px 10px;
  border: 1px solid #6e66fa;
  border-radius: 5px;
}
.text-inputs p {
  margin:0px;
}
#navbarContent form{max-width:440px;}
#navbarContent form input {
  border-radius: 100px;
}
header#custom-header ul li a:hover {
  background: transparent;
  font-size: 1;
}
header#custom-header {
  background: #04031A;
    z-index: 9999999999;
}
/*COntact form home */
#form-contact {
    background: #04031a;
    padding: 100px 0px;
}
.form-contact {
  background: #161A42;
  padding: 20px;
  border-radius: 45px;
}
.form-contact-2 {background: transparent linear-gradient(90deg, #6041D3 0%, #A08DE5 66%, #AB98E1 78%, #EDDACB 100%) 0% 0% no-repeat padding-box;padding: 20px;border-radius: 45px;}
section#email-subscription {
  background: #04031a;
}
section#banner-home {
  padding: 200px 100px;
  background: url(../src/assets/home/bg-home/Group-27.png) #04031A;
  min-height: 600px;
  background-repeat: no-repeat;
  background-position: 123% -100px;
  display: flex;
}
.bg-image-home{
  background: url(../src/assets/home/Gradation/Gradation.png);
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
    padding: 30px;
}
.bg-image-home img {
  width: 100%;
    // height: 100%;
    position: relative;
    z-index: 99;
}
.bg-image-home img.spiral {
  position: absolute;
  width: 101px;
  bottom: 0;
  right: -23%;
}

.bg-image-home img.cube {
  position: absolute;
  width: 120px;
  left: -35%;
  bottom: -30%;
}
h2.heading {
  color: #fff;
}
.what-nft-text p {
  color: #A4A1D3;
  font-size: 1rem;
  text-align: justify;
}
.btn.btn-read {
  color: #673DFF;
}
section.services .icon-wrap h3 {
  font-size: 1.2em;
  color: #fff;
  margin-top: 30px;
  font-weight: 100;
  margin-bottom: 20px;
}

section.services .icon-wrap p {
  color: #A4A1D3;
  font-size: 1rem;
  font-weight: 100;
  text-align: justify;
}

section.what-nft {
  background-image: linear-gradient(177deg, black, transparent);
  padding: 4rem 0;
}
.bg-image-home.Position-after::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: #d930e7;
  z-index: 1;
  border-radius: 20px;
  transform: rotate( 
-22deg
);
}







.bg-image-home.Position-after::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: #6041D3;
  z-index: 9;
  border-radius: 20px;
  transform: rotate(-15deg);
}


h2 {}

.banner-content h2 {
    font-size: 108px;
    color: #6041d3;
    font-weight: bold;
}

.banner-content h4 {
    font-size: 70px;
    color: #fff;
    margin: 0;
}

.banner-content p {
    font-size: 18px;
    color: #fff;
}
h2 {}

.banner-content h2 {
    font-size: 138px;
    color: #fff;
    font-weight: bold;
    background: -webkit-linear-gradient(#6041d3, #A08DE5, #EDDACB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 999999;
}

.banner-content h4 {
    font-size: 70px;
    color: #fff;
    margin: 0;
}

.banner-content p {
    font-size: 18px;
    color: #fff;}
    .banner-content a {
      background: -webkit-linear-gradient(#6041d3, #6041d3);
      padding: 10px 40px;
      display: inline-block;
      border-radius: 100px !important;
  }

  /* 28-4-2022 css start here  */


.marketbox {
  height: 580px;
  position: relative;
  padding: 50px;
  text-align: center;
  border: 1px solid #6041D3;
  border-radius: 30px;
  background: transparent linear-gradient(179deg, #161A42 0%, #161A4200 100%) 0% 0% no-repeat;
}
.marketcontent h2 {
  font-size: 25px;
  margin: 15px 0;
  color: #fff;
}
.marketcontent p {
  color: #A4A1D3;
  font-size: 16px;
  text-align: justify;

}
.marketcontent p span{
  color: #fff;
  font-size: 16px;
}
.marketbox-img img.img-fluid {
  width: 200px;
  height: 250px;
  position: absolute;
  top: -7rem;
  left: 50%;
  right: 0;
  transform: translate(-50%, 0%);}
  .marketcontent {
    margin-top: 7rem;
}
section.marketsec {
  padding: 8rem 0;
  background: #04031A;
}
section.marketsectwo {
  background: #04031A;
}

.nft {
  background: #04031A;
}
.coming-img {
  max-width: 50%;
  margin: 30px auto 30px;
  text-align: center;
}
.coming-img p {
  margin-top: 20px;
  color: #fff;
  font-size: 28px;
}
.set-content img.img-fluid {
  width: 40px;
  height: auto;
}
.set-content h3 {
  color: #fff;
  font-size: 16px;
  margin: 20px 0 0px;
}
.set-content p {
  color: #fff;
  margin: 5px 0;
  font-size: 14px;
  text-align: justify;
}
.rowinc {
  border: 1px solid #fff;
  padding: 30px;
  border-radius: 20px;
  background: #00000033 0% 0% no-repeat;
}
.learn h2 {
  color: #fff;
  text-align: center;
  font-size: 25px;
}
.learn p {
  color: #A4A1D3;
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
  text-align: justify;

}
.learn {
  max-width: 80%;
  margin: 0 auto;
}
.your{
  background: #04031A
}
.learn img.img-fluid {
  width: 25px;
  margin-left: 20px;
}
.direct h3 {
  background: transparent linear-gradient(
175deg
, #161A42 0%, #161A4200 100%) 0% 0% no-repeat padding-box;
  // border: 1px solid #6041d3;
  border-radius: 10px;
  text-align: center;
  padding: 9px 20px;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  display: inline-block;
}
.direct.comunuity {
  margin-left: 12rem;
  margin-bottom: 30px;
}
.brand-content {
  text-align: center;
  margin-top: 25px;
}
.direct {
  margin-left: 8rem;
}
.brand-content h4 {
  color: #A08DE5;
  text-transform: capitalize;
  font-size: 20px;
}
.brand-imgs {
  position: relative; margin: 10px 0;
}
.brand-imgs img.line.img-fluid {
  margin-left: 0;
}
.brand-imgs img.path.img-fluid {
  position: absolute;
  bottom: 0;
  left: 46.45%;
}
.bottom-content p {
  color: #A4A1D3;
  font-size: 16px;
  text-align: center;
  text-align: justify;
}
.bottom-content p span{
  color: #fff;
  font-size: 16px;
}
.bottom-content {
  max-width: 80%;
  margin: 20px auto 0;
}
.triangle img.img-fluid.tri-img {
  width: 70px;
}
.quick h3 {
  font-size: 18px;
  color: #FFF;
}
.quick ul {
  margin: 20px 0 0 0;
  padding: 0;
}
.quick ul li {
  list-style: none;
}
.quick ul li a {
  font-size: 14px;
  margin: 5px 0;
  display: block;
  color:#d3c7ff;
}

.quick ul li a:hover{
  color:#fff;
}
.footer-first p {
  font-size: 15px;
  margin-top: 20px;
  color:#A08DE5;
}
ul.socialicon {
  margin: 30px 0 0 0;
  padding: 0;
}
ul.socialicon li {
  list-style: none;
  display: inline;
  margin: 0 5px;
}
.socialicon li a {
  border: 1px solid #818181;
  border-radius: 5px;
  padding: 7px 8px;
  background: #555;
}
.text-center.border-top.copyright {
  margin-top: 30px;
  border-top: 1px solid #585858 !important;
}
.text-center.border-top.copyright p {
  margin: 0;
  padding: 15px 0;
  color:#fff;
}
.footercls {
  padding-top: 80px;
}
.quick ul li a i {
  color: #A08DE5;
  margin-right: 5px;
}
.triangle.tri-position img.img-fluid.tri-img {
  position: absolute;
  left: 0;
}
section.services {
  background: #04031A;
  padding: 50px 0;
}








////////////////////////////////client css starts//////////////////////////////////////

// html,
// body {
//     margin: 0;
//     background-color: #04031A !important;
//     overflow-x: hidden;
// }

:root {
    --light-voilet: #A4A1D3;
    --grey: #A4A1D347;
    --blue: #6041D3;
    --darkslategray: #282a42;
    --white: #ffffff;
    --dark-blue: #322F66;
}

@font-face {
    font-family: 'Clash Display Bold';
    src: url(./assets/clientfonts/fonts/clash_display/ClashDisplay-Bold.woff);
}

@font-face {
    font-family: 'Clash Display Medium';
    src: url(./assets/clientfonts/fonts/clash_display/ClashDisplay-Medium.woff);
}

@font-face {
    font-family: 'Inter Regular';
    src: url(./assets/clientfonts/fonts/inter/Inter-Regular.ttf);
}

@font-face {
    font-family: 'Inter Light';
    src: url(./assets/clientfonts/fonts/inter/Inter-Light.ttf);
}

// img {
//     width: 100%;
// }

h1 {
    font-family: 'Clash Display Medium';
}

.blog-header-content h1 {
    position: relative;
    background-image: -webkit-linear-gradient(90deg, #6041D3 20%, #A08DE5 36%, #EDDACB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    font-size: 56px !important;
    font-family: 'Clash Display Bold';
}

h2,
h3,
h4,
h5,
h6 {
    font-family: 'Clash Display Medium';
}

// p,
// li {
//     font-family: 'Inter light';
//     opacity: 87%;
//     line-height: 28px;
//     color: var(--light-voilet);
//     letter-spacing: 0.5px;
//     margin-bottom: 10px !important;
// }

li {
    font-size: 14px !important;
}

.blog-page {
    background: url(./assets/clientassets/Blog.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.blog-post img {

  width: 100%;

}
// li {
//     font-size: 14px !important;
// }

// .blog-page {
//     background: url(./assets/clientassets/Blog.png);
//     background-size: cover;
//     background-repeat: no-repeat;
// }

// .single-page-template {
//     background: url(./assets/clientassets/single-bg.png);
//     background-size: cover;
//     background-repeat: no-repeat;
// }

// .about-page {
//     background: url(./assets/clientassets/about-us-bg.png);
//     background-size: cover;
//     background-repeat: no-repeat;
// }

// .btn {
//     text-transform: uppercase;
//     font-family: 'Inter Semibold';
//     font-size: 14px !important;
//     letter-spacing: 1.28px !important;
//     line-height: 19px !important;
//     padding: 18px 25px !important;
// }


.single-page-template {
    background: url(./assets/clientassets/single-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.about-page {
    background: url(./assets/clientassets/about-us-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.btn {
    text-transform: uppercase;
    font-family: 'Inter Semibold';
    font-size: 14px !important;
    letter-spacing: 1.28px !important;
    line-height: 19px !important;
    padding: 18px 25px !important;
}

.blog-header-container {
    background-image: url(./assets/clientassets/blog-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.blog-header-content img {
    image-rendering: auto;
    width: 231px !important;
    height: 66px !important;
    margin-bottom: 25px;
}

.blog-header-container h1::after {
    position: absolute;
    content: url(./assets/clientassets/star.png);
    padding-left: 15px;
    top: 8px;
}

.slug {
    font-size: 20px;
    padding-top:100px;
    color: var(--grey) !important;
}

.blog-header-content {
    padding: 84px 0 143px;
}

.blog-header-content:after {
    position: absolute;
    content: url(./assets/clientassets/shape-tyre.png);
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    right: -150px;
    top: 5px;
}

.blog-header-content:before,
.coburn-project-col:after {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.blog-header-content:before {
    position: absolute;
    content: url(./assets/clientassets/shape_cone.png);
    bottom: -83px;
    left: -57px;
    bottom: 100px;
}

span.meta-time:before {
    position: absolute;
    content: url(./assets/clientassets/dot.png);
    left: 8px;
    top: -2px;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

.work-content p,
.meta {
    color: var(--light-voilet);
}

.meta {
    font-size: 16px;
    line-height: 46px;
}

.section-margin {
    margin: 100px 0 !important;
}

.section-margin-bottom {
    padding-bottom: 100px!important;
}

.nft_work img {
    height: 100%;
}

.gredient-btn,
.submit-btn {
    background:-webkit-linear-gradient(#6041d3, #6041d3) !important;
    border-radius: 49px !important;
    border: 1px solid var(--blue) !important;
    opacity: 1;
    padding: 13px 40px!important;
    transition: .3s all ease-in !important;
    line-height: 18px !important;
    letter-spacing: 1.28px !important;
    font-family: var(--bs-body-font-family);
}

.gredient-btn:hover,
.gredient-btn:focus,
.submit-btn:hover,
.submit-btn:hover {
    border: 1px solid var(--blue) !important;
    background: #7858f3 !important;
    transition: .3s all ease-in;
    color: var(--white) !important;
    box-shadow: none !important;
}

button.btn.gredient-btn:hover::after {
    right: 5px;
    transition: .3s all ease-in;
}

@media only screen and (max-width:1799px) and (min-width:1920px) {
    .gredeint-img {
        background-image: url(./assets/clientassets/shape-gredient1.png);
        width: 100%;
        height: 158%;
        background-repeat: no-repeat;
        right: -32px !important;
        top: -143px;
        background-position: center right;
    }
    .about-whiteroom-col,
    .parasol-projects,
    .coburn-project-col {
        padding-top: 60px !important;
    }
}

.about-whiteroom-col,
.coburn-project-col {
    padding-top: 40px !important;
}

@media only screen and (max-width:1600px) {
    .gredeint-img {
        background-image: url(./assets/clientassets/shape-gredient1.png);
        width: 100%;
        height: 158%;
        background-repeat: no-repeat;
        right: -32px !important;
        top: 200px;
        background-position: center right;
    }
}


/* tabs */

.nav-pills .nav-link.active {
    background-color: var(--grey) !important;
    border-radius: 50px;
}

li.nav-item {
    // border: 1px solid var(--blue) !important;
    // border-radius: 50px;
    margin: 0 10px;
}

.tab-content {
    margin-top: 30px;
}

.description {
    // color: var(--light-voilet);
    // font-size: 16px;
    // line-height: 26px;
    color: var(--light-voilet);
    font-size: 16px;
    line-height: 26px;
    height: 100%;
    min-height: 170px;
    text-align: justify;
}

p.meta:before,
.blog-post .meta:before {
    content: url(./assets/clientassets/calender.png);
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 4px;
}


/* tabs */

@media screen and (max-width:1440px) and (min-width:1024px) {
    .blog-header-content:after {
        right: 50px;
        top: 5px;
    }
    .blog-header-content:before {
        left: 57px;
        bottom: 100px;
    }
    .newsletter-box {
        padding: 30px 30px !important;
    }
    .newsletter-box input {
        padding: 15px 30px !important;
    }
}


/* single post template */

.nft-icons img {
    max-width: 50% !important;
}

.nft-icons h4 {
    margin-left: -25px;
}

.img-single img {
    width: 100%;
    max-width: 75%;
}

hr {
    margin: 1.2rem 0;
    color: var(--dark-blue);
    opacity: 0.2;
}

.border-li::before {
    border: 1px solid #a4a1d37d !important;
    opacity: 1 !important;
}

.single-text :before,
.border-li::before {
    width: 0px;
    height: 100%;
    max-height: 95%;
    border: 1px solid var(--light-voilet);
    opacity: 0.36;
    position: ABSOLUTE;
    content: '';
    top: 9px;
    left: -5px;
}

.nft-buy-img {
    width: 100%;
    max-width: 994px !important;
    height: 444px;
}

.nft-list1 img {
    width: 30px !important;
    height: 21px;
    padding-right: 10px;
}

.nft-work-cols img {
    width: 100%;
    max-width: 55% !important;
}

.single-icon-text img {
    max-width: 70%;
}

.conclusions-cols img {
    max-width: 48%;
}





/* about us page */

.rectangle-bg {
    background-image: url(./assets/clientassets/rectangle.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: inherit;
}

.rectangle-bg img.gredient-img-bg {
  width: 100%;
  max-width: 300px;
  border-radius: 20px;
  margin: 34px 0px;
}

.about-whiteroom-col:after {
    content: url(./assets/clientassets/dice.png);
    position: absolute;
    right: 50px;
    bottom: -50px;
    transform-style: preserve-3d;
    transform: rotateX(-35deg) rotateY(35deg);
    animation: view 2s linear infinite;
    transform: rotateY(45deg);
    animation: rotateAnimation 4s linear infinite;
}

@keyframes rotateAnimation {
    20% {
        transform: rotateY(45deg);
    }
    50% {
        transform: rotateX(120deg);
    }
    100% {
        transform: rotateY(120deg);
    }
}

.coburn-project-col:after {
    content: url(./assets/clientassets/shape_cone.png);
    position: absolute;
    left: 0px;
    bottom: -106px;
}

.form-container {
    background-color: rgba(22, 26, 66, 0.46);
    border-radius: 10px;
    box-shadow: 0px 8px 12px 0px #8f8cba4d;
    margin-top: 40px;
    padding: 40px 40px;
}

.form-container,
.form-container input {
    border: 1px solid rgb(255 255 255 / 25%);
}

.form-container input,
.form-container textarea {
    background-color: var(--darkslategray);
    border: 1px solid rgb(255 255 255 / 25%);
    padding: 10px 20px;
    transition: all .2s linear;
}

.form-container textarea.form-control {
    height: 120px;
}

textarea.form-control:focus,
input.form-control:focus {
    color: rgb(158, 154, 154) !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid var(--bs-gray);
    transition: all .2s linear;
}

.social-icons img {
    max-width: 80%;
}


.section-margin1 {
  margin: 85px 0px 0 ;
  padding: 40px
}
.margin-bottom-cus{
  margin: 85px 0px 85px !important ;
}
.container-fluid.main-about p {

  color: var(--light-voilet);
  text-align: justify;
  font-size: 17px;

}
.container-fluid.main-about li{margin: 10px 0;color: var(--light-voilet);}
.container-fluid.main-about img {
  width: 100%;
}


.btn.btn-read:hover {
  color: #ffffff;
}
.custom-height {height: 100%;min-height: 300px;}

.custom-height img {height: 100%;
  object-fit: cover;
  padding: 0;
  border-radius: 20px;
  min-height: 250px;}
.custom-height-2 img {border-radius: 20px;padding: 0;}
.image-box {width: 165px;height: 165px;margin-left: 20px;}

.image-box img {width: 100%;height: 100%;object-fit: cover;}
.min-height-image img {
  min-height: 120px;
}

@media (max-width:992px){
  .image-box {
    width: auto;
    height: 235px;
    margin-left: 0;
}
}
@media (max-width:786px) {
  .res-full-width.col-sm-6 {
    width: 100% !important;
    margin-bottom: 20px;
    min-height: 250px;
      
  }
  .image-box {
    width: 185px;
    height: 185px;
    margin-left: 20px;
}
  .col-reverse {
      flex-direction: column-reverse;
  }
  .conclusions-cols img,
  .nft-work-cols img {
      max-width: 36% !important;
      padding: 20px 0;
  }
}
@media only screen and (max-width:575px) {
  .blog-header-container h1::after {
      padding-left: 7px;
      top: 0px;
  }
  .blog-header-content h1 {
      font-size: 42px !important;
  }
  .about-whiteroom-col.pt-5,
  .coburn-project-col {
      padding-top: 15px !important;
  }
  .gredeint-img,
  .blog-header-content:after,
  .blog-header-content:before,
  .gredeint-img-left,
  .gredeint-img1,
  .gredeint-img2,
  .coburn-project-col:after,
  .about-whiteroom-col:after {
      display: none !important;
  }
  p {
      line-height: 24px !important;
  }
  .slug {
      padding: 30px;
  }
  .blog-header-content img {
      width: 190px !important;
  }
  .blog-header-content {
      padding: 40px 0 70px;
  }
  .section-margin {
      margin: 50px 0px !important;
  }
  .section-margin-bottom {
      padding-bottom: 50px!important;
  }
  .about-us img {}
  .rectangle-bg {
      background-position: center center !important;
      text-align: center !important;
  }
  .meta {
      font-size: 13px !important;
      margin-top: 5px;
  }
  .tab-content {
      padding: 0;
      margin: 0;
  }
  .single-text :before {
      content: none !important;
  }
  .img-single img {
      max-width: 100% !important;
  }
  .rectangle-bg img.gredient-img-bg {
      max-width: 200px !important;
      margin: 0px !important;
      
  }
  .form-container {
      margin-top: 30px;
      padding: 20px 20px;
  }
  .image-box {
    width: auto;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    padding: 20px;
    max-width: 270px;
    margin: 0 auto;
}
.res-full-width.col-sm-6 {
  width: 100% !important;
  margin-bottom: 20px;
  min-height: auto;
}
}